@import '~antd/lib/style/themes/default.less';
.signup-view {
  width: 348px;
  margin: 0 auto;

  h3 {
    margin-bottom: 20px;
    font-size: 16px;
  }
  .recaptcha {
    width: 100%;
  }

  .password {
    margin-bottom: 24px;
    :global {
      .ant-form-item-explain {
        display: none;
      }
    }
  }

  .getCaptcha {
    display: block;
    width: 100%;
  }

  .submit {
    width: 45%;
  }

  .login {
    float: right;
    line-height: @btn-height-lg;
  }
}
.success,
.warning,
.error {
  transition: color 0.3s;
}

.success {
  color: @success-color;
}

.warning {
  color: @warning-color;
}

.error {
  color: @error-color;
}

.progress-pass > .progress {
  :global {
    .ant-progress-bg {
      background-color: @warning-color;
    }
  }
}

@primary-color: #2a5cad;@theme: [object Object];