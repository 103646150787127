.public-viewer-view {
  .ant-pro-page-container-children-content {
    height: calc(100vh - 88px);
    margin: 0px 0px 0;
  }
  .ant-page-header {
    padding: 0px 24px;
  }
  // .ant-page-header.has-breadcrumb {
  //   padding-top: 2px;
  // }
  .ant-page-header-heading-sub-title {
    height: 22px;
  }
  .site-result-demo-error-icon {
    color: red;
  }
  [data-theme='dark'] .site-result-demo-error-icon {
    color: #a61d24;
  }

  // .ant-layout,
  // .ant-layout * :disabled {
  //   box-sizing: border-box;
  // }
  // [class^='ant-'],
  // [class*='ant-'],
  // [class^='ant-'] *:disabled,
  // [class*='ant-'] :disabled,
  // [class^='ant-'] *::before,
  // [class*='ant-'] *::before,
  // [class^='ant-'] *::after,
  // [class*='ant-'] *::after {
  //   box-sizing: border-box;
  // }
  // .adsk-viewing-viewer .docking-panel {
  //   background: rgba(34, 34, 34, 0.8) !important;
  // }
}

@primary-color: #2a5cad;@theme: [object Object];