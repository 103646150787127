@import '~antd/lib/style/themes/default.less';

.ant-container > * {
  background-color: @popover-bg;
  border-radius: 4px;
  box-shadow: @shadow-1-down;
}

@media screen and (max-width: @screen-xs) {
  .ant-container {
    width: 100% !important;
  }
  .ant-container > * {
    border-radius: 0 !important;
  }
}

@primary-color: #2a5cad;@theme: [object Object];