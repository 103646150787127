@import '~antd/es/style/themes/default.less';

.textOverflow() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

// mixins for clearfix
// ------------------------
.clearfix() {
  zoom: 1;
  &::before,
  &::after {
    display: table;
    content: ' ';
  }
  &::after {
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden;
  }
}

.activitiesList {
  //padding: 0 24px 8px 24px;
  .username {
    color: @text-color;
  }
  .event {
    font-weight: normal;
  }
  // .ant-list-item:hover {
  //   box-shadow: 0 0 10px #eee;
  // }
}

.pageHeaderContent {
  display: flex;
  .avatar {
    margin: auto;
    flex: 0 1 72px;
    & > span {
      display: block;
      width: 72px;
      height: 72px;
      border-radius: 72px;
    }
  }
  .content {
    position: relative;
    top: 4px;
    flex: 1 1 auto;
    margin-left: 24px;
    color: @text-color-secondary;
    line-height: 22px;
    .contentTitle {
      margin-bottom: 12px;
      color: @heading-color;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
.dashboard {
  .members a.memberTitle {
    color: rgb(115, 115, 115);
  }

  .card {
    max-width: 350px;
    :global {
      .ant-card-meta-title {
        margin-bottom: 4px;
        & > a {
          display: inline-block;
          max-width: 100%;
          color: @heading-color;
        }
      }
      .ant-card-meta-description {
        height: 44px;
        overflow: hidden;
        line-height: 22px;
      }
    }

    &:hover {
      :global {
        .ant-card-meta-title > a {
          color: @primary-color;
        }
      }
    }
  }
  .ant-card-cover {
    height: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    //display: flex;
    .cardAvatar {
      height: 100%;
      margin: auto;
      padding: 1px;
    }
  }
  // .ant-card-meta {
  //   height: 100px;
  // }
  .cardItemContent {
    display: flex;
    height: 20px;
    margin-bottom: -4px;
    line-height: 20px;
    & > span {
      flex: 1;
      color: @text-color-secondary;
      font-size: 12px;
    }
    .avatarList {
      flex: 0 1 auto;
    }
  }
  .ant-card-meta-description > a {
    height: 44px;
    overflow: hidden;
    color: @text-color-secondary;
    line-height: 22px;
  }
}

.extraContent {
  .clearfix();

  float: right;
  white-space: nowrap;
  .statItem {
    position: relative;
    display: inline-block;
    padding: 0 32px;
    > p:first-child {
      margin-bottom: 4px;
      color: @text-color-secondary;
      font-size: @font-size-base;
      line-height: 22px;
    }
    > p {
      margin: 0;
      color: @heading-color;
      font-size: 30px;
      line-height: 38px;
      > span {
        color: @text-color-secondary;
        font-size: 20px;
      }
    }
    &::after {
      position: absolute;
      top: 8px;
      right: 0;
      width: 1px;
      height: 40px;
      background-color: @border-color-split;
      content: '';
    }
    &:last-child {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
  }
}
// .members a.memberTitle {
//   color: @text-color-secondary;
// }
// .members {
//   a {
//     padding-left: 10px;
//     height: 24px;
//     margin: 12px 0;
//     color: white;
//     transition: all 0.3s;
//     .textOverflow();
//     .member {
//       margin-left: 12px;
//       font-size: @font-size-base;
//       line-height: 24px;
//       vertical-align: top;
//     }
//     &:hover {
//       color: @primary-color;
//     }
//   }
// }

.projectList {
  :global {
    .ant-card-meta-description {
      height: 44px;
      overflow: hidden;
      color: @text-color-secondary;
      line-height: 22px;
    }
  }
  .cardTitle {
    font-size: 0;
    a {
      display: inline-block;
      height: 24px;
      margin-left: 12px;
      color: @heading-color;
      font-size: @font-size-base;
      line-height: 24px;
      vertical-align: top;
      &:hover {
        color: @primary-color;
      }
    }
  }
  .projectGrid {
    width: 33.33%;
  }
  .projectItemContent {
    display: flex;
    height: 20px;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    line-height: 20px;
    .textOverflow();
    a {
      display: inline-block;
      flex: 1 1 0;
      color: @text-color-secondary;
      .textOverflow();
      &:hover {
        color: @primary-color;
      }
    }
    .link {
      display: inline-block;
      flex: 1 1 0;
      color: @text-color-secondary;
      .textOverflow();
      &:hover {
        color: @primary-color;
      }
    }
    .datetime {
      flex: 0 0 auto;
      float: right;
      color: @disabled-color;
    }
  }
}

.datetime {
  color: @disabled-color;
}

@media screen and (max-width: @screen-xl) and (min-width: @screen-lg) {
  .activeCard {
    margin-bottom: 24px;
  }
  .members {
    margin-bottom: 0;
  }
  .extraContent {
    margin-left: -44px;
    .statItem {
      padding: 0 16px;
    }
  }
}

@media screen and (max-width: @screen-lg) {
  .activeCard {
    margin-bottom: 24px;
  }
  .members {
    margin-bottom: 0;
  }
  .extraContent {
    float: none;
    margin-right: 0;
    .statItem {
      padding: 0 16px;
      text-align: left;
      &::after {
        display: none;
      }
    }
  }
}

@media screen and (max-width: @screen-md) {
  .extraContent {
    margin-left: -16px;
  }
  .projectList {
    .projectGrid {
      width: 50%;
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .pageHeaderContent {
    display: block;
    .content {
      margin-left: 0;
    }
  }
  .extraContent {
    .statItem {
      float: none;
    }
  }
}

@media screen and (max-width: @screen-xs) {
  .projectList {
    .projectGrid {
      width: 100%;
    }
  }
}

@primary-color: #2a5cad;@theme: [object Object];