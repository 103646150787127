.matterport-viewer-app-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  .matterport-viewer-container {
    position: absolute;
    overflow: hidden;
    width: 100%;

    .responsive-iframe {
      border-width: 2px;
      border-style: inset;
      border-color: initial;
      border-image: initial;
    }
    .docking-panel {
      text-align: left;

      .docking-panel-close {
        top: -6px;
      }
    }
  }
}
.matterport-viewer-app-container .title {
  color: @primary-color;
  display: flex;
}
.matterport-viewer-app-container .title > .dropdown {
  position: relative;
  top: -1px;
}

.matterport-viewer-app-container .title > .dropdown > button {
  padding: 2px 12px;
}

.matterport-viewer-app-container .title .dropdown-menu {
  overflow: scroll;
}

.matterport-viewer-app-container .title .ant-dropdown-button > button {
  color: rgba(0, 0, 0, 0.45);
}

.matterport-viewer-app-container .title > label {
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // white-space: nowrap;
  // font-weight: 400;
  // user-select: none;
  margin-left: 27px;
  // width: 9pc;
  float: left;
  width: 20%;
  margin-top: '8px !important';
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.matterport-viewer-app-container .title {
  overflow: visible;
  float: left;
  position: relative !important;
}

@primary-color: #2a5cad;@theme: [object Object];