@import '~antd/lib/style/themes/default.less';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background-image: url('https://bimensionstorage.blob.core.windows.net/bim-forge-public/images%2Flogin_background.jpg');
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .lang {
    width: 100%;
    height: 40px;
    line-height: 44px;
    text-align: right;
    :global(.ant-dropdown-trigger) {
      margin-right: 24px;
    }
  }

  .content {
    flex: 1;
    padding: 32px 0;
  }

  .ant-layout-footer {
    background: transparent !important;
  }
  .ant-layout-footer > * {
    color: white;
  }

  .top {
    text-align: center;
  }

  .header {
    height: 56px;
    line-height: 56px;
    a {
      text-decoration: none;
    }
  }

  .logo {
    height: 56px;
    margin-right: 16px;
    vertical-align: top;
  }

  .title {
    position: relative;
    top: 2px;
    color: white;
    font-weight: 600;
    font-size: 33px;
    font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  .desc {
    margin-top: 12px;
    margin-bottom: 40px;
    color: white;
    font-size: @font-size-base;
  }
}

@media (min-width: @screen-md-min) {
  .container {
    //background-image: url('https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg');
    // background-repeat: no-repeat;
    // background-position: center 110px;
    // background-size: 100%;
    background-image: url('https://bimensionstorage.blob.core.windows.net/bim-forge-public/images%2Flogin_background.jpg');
    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    padding: 32px 0 24px;
  }
}

@primary-color: #2a5cad;@theme: [object Object];