@import '~antd/lib/style/themes/default.less';

.headerSearch {
  .input {
    width: 0;
    min-width: 0;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    transition: width 0.3s, margin-left 0.3s;
    :global(.ant-select-selection) {
      background: transparent;
    }
    input {
      padding-right: 0;
      padding-left: 0;
      border: 0;
      box-shadow: none !important;
    }
    &,
    // &:hover,
    &:focus {
      border-bottom: 1px solid @border-color-base;
    }
    &.show {
      width: 210px;
      margin-left: 8px;
    }
  }
}

@primary-color: #2a5cad;@theme: [object Object];