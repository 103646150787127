.viewer-app-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  .viewer-container {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .adsk-viewing-viewer.light-theme .adsk-button:hover {
      border: 1px solid orange;
      color: orange;
    }

    .adsk-viewing-viewer.light-theme .adsk-button.active {
      color: orange;
    }

    .docking-panel {
      text-align: left;

      .docking-panel-close {
        top: -6px;
      }
    }
  }
}

/* ========================================================================== */
/*            fix ant-design global style override for all elements           */
/* ========================================================================== */

// .adsk-viewing-viewer * {
//   -webkit-box-sizing: content-box !important;
//   -moz-box-sizing: content-box !important;
//   box-sizing: content-box !important;
// }
// .adsk-viewing-viewer .adsk-searchbox input.search-box {
//   box-sizing: border-box !important;
// }

// .adsk-viewing-viewer .treeview lmvheader {
//   -webkit-box-sizing: border-box !important;
//   -moz-box-sizing: border-box !important;
//   box-sizing: border-box !important;
// }

@primary-color: #2a5cad;@theme: [object Object];