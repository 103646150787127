@import "~antd/es/style/themes/default.less";

.baseView {
  display: flex;
  padding-top: 12px;

  :global {
    .ant-legacy-form-item .ant-legacy-form-item-control-wrapper {
      width: 100%;
    }
  }

  .left {
    min-width: 224px;
    max-width: 448px;
    .ant-col {
      max-width: 100% !important;
    }
  }
  .settings-right {
    flex: 1;
    padding-left: 104px;
    .avatar_title {
      height: 22px;
      margin-bottom: 8px;
      color: @heading-color;
      font-size: @font-size-base;
      line-height: 22px;
    }
    .avatar {
      width: 144px;
      height: 144px;
      margin-bottom: 12px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .button_view {
      width: 144px;
      text-align: center;
    }
  }
}

@media screen and (max-width: @screen-xl) {
  .baseView {
    flex-direction: column-reverse;
    .left {
      min-width: 224px !important;
      max-width: 500px;
      .ant-col {
        max-width: 100% !important;
      }
    }

    .settings-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 448px;
      padding: 20px;
      .avatar_title {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .baseView {
    .left {
      min-width: 448px !important;
      max-width: 500px;
      .ant-col {
        max-width: 100% !important;
      }
    }
  }
}

@primary-color: #2a5cad;@theme: [object Object];