@import '~antd/es/style/themes/default.less';

.listContent {
  .description {
    max-width: 720px;
    line-height: 22px;
  }
  .extra {
    margin-top: 16px;
    color: @text-color-secondary;
    line-height: 22px;
    & > :global(.ant-avatar) {
      position: relative;
      top: 1px;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      vertical-align: top;
    }
    & > em {
      margin-left: 16px;
      color: @disabled-color;
      font-style: normal;
    }
  }
  .dropdown {
    margin-top: 16px;
  }
}

@media screen and (max-width: @screen-xs) {
  .listContent {
    .extra {
      & > em {
        display: block;
        margin-top: 8px;
        margin-left: 0;
      }
    }
  }
}

@primary-color: #2a5cad;@theme: [object Object];