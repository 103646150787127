@import '~antd/lib/style/themes/default.less';

.global-header {
  .ant-list {
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .ant-item {
      padding-right: 24px;
      padding-left: 24px;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s;

      .ant-meta {
        width: 100%;
      }

      .avatar {
        margin-top: 4px;
        background: @component-background;
      }
      .iconElement {
        font-size: 32px;
      }

      &.read {
        opacity: 0.4;
      }
      &:last-child {
        border-bottom: 0;
      }
      &:hover,
      &:focus {
        background-color: var(--primary-hover-color);
      }
      .ant-title {
        margin-bottom: 8px;
        font-weight: normal;
      }
      .ant-description {
        font-size: 12px;
        line-height: @line-height-base;
      }
      .ant-datetime {
        margin-top: 4px;
        font-size: 12px;
        line-height: @line-height-base;
      }
      .ant-extra {
        float: right;
        margin-top: -1.5px;
        margin-right: 0;
        color: @text-color-secondary;
        font-weight: normal;
      }
    }
    .loadMore {
      padding: 8px 0;
      color: @primary-6;
      text-align: center;
      cursor: pointer;
      &.loadedAll {
        color: rgba(0, 0, 0, 0.25);
        cursor: unset;
      }
    }
  }

  .notFound {
    padding: 73px 0 88px;
    //color: @text-color-secondary;
    text-align: center;
    img {
      display: inline-block;
      height: 76px;
      margin-bottom: 16px;
    }
  }

  .bottomBar {
    height: 46px;
    //color: @text-color;
    line-height: 46px;
    text-align: center;
    border-top: 1px solid @border-color-split;
    border-radius: 0 0 @border-radius-base @border-radius-base;
    transition: all 0.3s;
    div {
      display: inline-block;
      width: 50%;
      cursor: pointer;
      transition: all 0.3s;
      user-select: none;

      &:only-child {
        width: 100%;
      }
      &:not(:only-child):last-child {
        border-left: 1px solid @border-color-split;
      }
      &:hover,
      &:focus {
        color: var(--primary-hover-color);
      }
    }
  }
}

@primary-color: #2a5cad;@theme: [object Object];