@import '~antd/es/style/themes/default.less';
.configurator-loader {
  height: calc(100vh - 65px);
  position: relative;
  top: 0px;
}

.configurator .data-pane {
  background-color: #fdfdfd;
  height: 100%;
}

.configurator .spinner {
  display: none;
}

.configurator .adsk-viewing-viewer.empty {
  pointer-events: none;
}

.configurator .empty .adsk-control,
.configurator .empty .viewcubeUI,
.configurator .empty .progressbg {
  display: none;
}
.configurator-toolbar {
  top: calc(50% + 250.5px);
  left: 0%;
  z-index: 100;
  position: absolute;
  //   position: absolute;
  //   bottom: 75px;
  //   left: 10px;
  //   height: calc(100% - 20px);
  //   text-align: center;
}
// .configurator .camera-sync-btn {
//   color: @primary-color;
//   border-color: @primary-color;
// }
// .markup-tb {
//   position: absolute;
//   bottom: 75px;
//   right: 10px;
//   //height: calc(100% - 20px);
//   height: 100%;
//   text-align: center;
// }

.configurator-toolbar #toolbar-markup3D .adsk-control-tooltip {
  transform: translate(-65px, 25px);
}

.configurator-toolbar #toolbar-transform .adsk-control-tooltip {
  transform: translate(-140px, 20px);
}

.configurator-toolbar #toolbar-translate .adsk-control-tooltip {
  transform: translate(-125px, -20px);
}

.configurator-toolbar #toolbar-rotate .adsk-control-tooltip {
  transform: translate(-115px, -15px);
}

.adn-docking-panel {
  top: 10px;
  left: 10px;
}

.adn-sheets-browser-panel {
  max-width: 315px;
  max-height: 280px;
  width: 315px;
  height: 280px;
  left: 10px;
  top: 225px;
}

.adn-sheets-browser-panel .docking-panel-footer {
  z-index: 1;
}

@primary-color: #2a5cad;@theme: [object Object];