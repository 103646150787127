@import '~antd/lib/style/themes/default.less';

@pro-header-hover-bg: rgba(0, 0, 0, 0.025);

.menu {
  :global(.anticon) {
    margin-right: 8px;
  }
  :global(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}

.right {
  display: flex;
  float: right;
  height: 48px;
  margin-left: auto;
  overflow: hidden;
  .action {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;
    > span {
      vertical-align: middle;
    }
    // &:hover {
    //   background: @pro-header-hover-bg;
    // }
    &:global(.opened) {
      background: @pro-header-hover-bg;
    }
  }
  .search {
    padding: 0 12px;
    // &:hover {
    //   background: transparent;
    // }
  }
  .account {
    .avatar {
      margin: ~'calc((@{layout-header-height} - 24px) / 2)' 0;
      margin-right: 8px;
      color: @primary-color;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.85);
    }
  }
}

.dark {
  .action {
    color: rgba(255, 255, 255, 0.85);
    > span {
      color: rgba(255, 255, 255, 0.85);
    }
    // &:hover,
    // &:global(.opened) {
    //   background: @primary-color;
    // }
  }
}

:global(.ant-pro-global-header) {
  .dark {
    .action {
      color: @text-color;
      > span {
        color: @text-color;
      }
      // &:hover {
      //   color: rgba(255, 255, 255, 0.85);
      //   > span {
      //     color: rgba(255, 255, 255, 0.85);
      //   }
      // }
    }
  }
}
@media only screen and (max-width: @screen-md) {
  :global(.ant-divider-vertical) {
    vertical-align: unset;
  }
  .name {
    display: none;
  }
  .right {
    position: absolute;
    top: 0;
    right: 12px;
    .account {
      .avatar {
        margin-right: 0;
      }
    }
    .search {
      display: none;
    }
  }
}

@primary-color: #2a5cad;@theme: [object Object];