@import '~antd/es/style/themes/default.less';

.project-view {
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    bottom: 15px !important;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: transparent !important;
  }
}
.main {
  :global {
    .ant-descriptions-row > td {
      padding-bottom: 8px;
    }
    .ant-page-header-heading-extra {
      flex-direction: column;
    }
  }
}
// .ant-page-header {
//   background-image: url('https://ing-wuest.de/wp-content/themes/wuest/assets/img/logo.png');

//   background-repeat: no-repeat;
//   background-size: auto;
// }
.extraImg {
  max-width: 200px;
  max-height: 200px;
  text-align: center;
  img {
    width: 100%;
  }
}

.headerList {
  margin-bottom: 4px;
  :global {
    .ant-descriptions-row > td {
      padding-bottom: 8px;
    }
  }

  .stepDescription {
    position: relative;
    left: 38px;
    padding-top: 8px;
    font-size: 14px;
    text-align: left;

    > div {
      margin-top: 8px;
      margin-bottom: 4px;
    }
  }
}

.pageHeader {
  :global {
    .ant-page-header-heading-extra > * + * {
      margin-left: 8px;
    }
  }
  .moreInfo {
    display: flex;
    justify-content: space-between;
    width: 200px;
  }
  // .ant-tabs {
  //   float: left;
  // }
  .ant-page-header-footer {
    min-height: 40px;
  }
}

@media screen and (max-width: @screen-sm) {
  .stepDescription {
    left: 8px;
  }
  .pageHeader {
    :global {
      .ant-pro-page-header-wrap-row {
        flex-direction: column;
      }
    }
  }
}

@primary-color: #2a5cad;@theme: [object Object];