.public-matterport-view {
  .ant-pro-page-container-children-content {
    height: calc(100vh - 88px);
    margin: 0px 0px 0;
  }
  .ant-page-header {
    padding: 0px 24px;
  }
  // .ant-page-header.has-breadcrumb {
  //   padding-top: 2px;
  // }
}
.ant-design-pro {
  max-height: 944px !important;
}
// .ant-pro-basicLayout {
//   height: 100vh;
// }

@primary-color: #2a5cad;@theme: [object Object];