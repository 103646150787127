@import '~antd/lib/style/themes/default.less';

.right {
  .noticeButton {
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s;
  }
  .icon {
    padding: 4px;
    vertical-align: middle;
  }

  .badge {
    font-size: 16px;
  }
}
.ant-popover {
  position: relative;
  width: 336px;
}

.ant-tabs {
  .ant-tabs-nav-list {
    margin: auto;
  }

  .ant-tabs-nav-scroll {
    text-align: center;
  }
  .ant-tabs-bar {
    margin-bottom: 0;
  }
}

@primary-color: #2a5cad;@theme: [object Object];