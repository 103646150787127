.matterport-view {
  .ant-pro-page-container-children-content {
    height: calc(100vh - 115px);
    margin: 0px 0px 0;
  }
  .ant-page-header {
    padding: 0px 24px;
  }
  // .ant-page-header.has-breadcrumb {
  //   padding-top: 2px;
  // }
}

@primary-color: #2a5cad;@theme: [object Object];