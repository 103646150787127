.signin-view {
  width: 348px;
  margin: 0 auto;
  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .ant-col-rtl .login-form-register {
    float: right;
  }
  .login-form-button {
    width: 100%;
  }
}

@primary-color: #2a5cad;@theme: [object Object];