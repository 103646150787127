.request-password-reset {
  width: 348px;
  margin: 0 auto;

  .request-password-form-login {
    float: left;
  }
  .request-password-form-register {
    float: right;
  }
  .request-password-form-button {
    width: 100%;
  }
}
@primary-color: #2a5cad;@theme: [object Object];