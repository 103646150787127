@import '~antd/lib/style/themes/default.less';
.loader-background {
  transition-timing-function: ease;
  transition-property: none;
  transition-duration: 2.5s;
  transition-delay: 0s;
  background: #282828a9;
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 10;
  left: 0px;
  top: 0px;
}

.loader-background.disabled {
  background: transparent !important;
  pointer-events: none;
}

.loader-background.disabled .loader {
  display: none;
}

.loader-background .loader {
  display: block;
}

// .loader,
// .loader:before,
// .loader:after {
//   top: 35%;
//   border-radius: 50%;
//   width: 2.5em;
//   height: 2.5em;
//   -webkit-animation-fill-mode: both;
//   animation-fill-mode: both;
//   -webkit-animation: load7 1.8s infinite ease-in-out;
//   animation: load7 1.8s infinite ease-in-out;
// }

// .loader {
//   color: #1677e3;
//   font-size: 10px;
//   margin: 0px auto;
//   position: relative;
//   text-indent: -9999em;
//   -webkit-transform: translateZ(0);
//   -ms-transform: translateZ(0);
//   transform: translateZ(0);
//   -webkit-animation-delay: -0.16s;
//   animation-delay: -0.16s;
// }

// .loader:before,
// .loader:after {
//   content: '';
//   position: absolute;
//   top: 0;
// }

// .loader:before {
//   left: -3.5em;
//   -webkit-animation-delay: -0.32s;
//   animation-delay: -0.32s;
// }

// .loader:after {
//   left: 3.5em;
// }

// @-webkit-keyframes load7 {
//   0%,
//   80%,
//   100% {
//     box-shadow: 0 2.5em 0 -1.3em;
//   }
//   40% {
//     box-shadow: 0 2.5em 0 0;
//   }
// }

// @keyframes load7 {
//   0%,
//   80%,
//   100% {
//     box-shadow: 0 2.5em 0 -1.3em;
//   }
//   40% {
//     box-shadow: 0 2.5em 0 0;
//   }
// }
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  background: #c8c8c84e;
  top: 30%;
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 23, 60, 0.2);
  border-right: 1.1em solid rgba(0, 23, 60, 0.2);
  border-bottom: 1.1em solid rgba(0, 23, 60, 0.2);
  border-left: 1.1em solid @primary-color;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@primary-color: #2a5cad;@theme: [object Object];