@import '~antd/lib/style/themes/default.less';

.main {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  overflow: auto;
  //background-color: @component-background;
  .leftMenu {
    width: 224px;
    border-right: @border-width-base @border-style-base @border-color-split;
    :global {
      .ant-menu-inline {
        border: none;
      }
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        font-weight: bold;
        //background-color: @text-selection-bg !important;
      }
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      font-weight: bold;
      //background-color: #0027669e;
      color: white;
    }
  }
  .account-right {
    flex: 1;
    padding-top: 8px;
    padding-right: 40px;
    padding-bottom: 8px;
    padding-left: 40px;
    .title {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
  :global {
    .ant-list-split .ant-list-item:last-child {
      border-bottom: 1px solid @border-color-split;
    }
    .ant-list-item {
      padding-top: 14px;
      padding-bottom: 14px;
    }
  }
  .members a.memberTitle {
    color: @text-color;
  }
}
:global {
  .ant-list-item-meta {
    // 账号绑定图标
  }

  font.strong {
    color: @success-color;
  }
  font.medium {
    color: @warning-color;
  }
  font.weak {
    color: @error-color;
  }
}

@media screen and (max-width: @screen-md) {
  .main {
    flex-direction: column;
    .leftMenu {
      width: 100%;
      border: none;
    }
    .account-right {
      padding: 40px;
    }
  }
}

@primary-color: #2a5cad;@theme: [object Object];