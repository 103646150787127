@import '~antd/es/style/themes/default.less';

a.listItemMetaTitle {
  color: rgb(115, 115, 115);
}
.listItemExtra {
  width: 272px;
  height: 1px;
}
.selfTrigger {
  margin-left: 12px;
}
.ant-list-item:hover {
  box-shadow: 0 0 4px rgb(182, 182, 182);
}

@media screen and (max-width: @screen-xs) {
  .selfTrigger {
    display: block;
    margin-left: 0;
  }
}
@media screen and (max-width: @screen-md) {
  .selfTrigger {
    display: block;
    margin-left: 0;
  }
}
@media screen and (max-width: @screen-lg) {
  .listItemExtra {
    width: 0;
    height: 1px;
  }
}

@primary-color: #2a5cad;@theme: [object Object];