.request-verification-email {
  width: 348px;
  margin: 0 auto;

  .request-verification-email-form-login {
    float: left;
  }
  .request-verification-email-form-register {
    float: right;
  }
  .request-verification-email-form-button {
    width: 100%;
  }
}

@primary-color: #2a5cad;@theme: [object Object];