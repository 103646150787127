@import '~antd/es/style/themes/default.less';

.avatarHolder {
  margin-bottom: 24px;
  text-align: center;

  & > img {
    max-width: 300px;
    max-height: 200px;
    height: auto;
    margin-bottom: 20px;
  }

  .name {
    margin-bottom: 4px;
    color: @text-color;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
}

.detail {
  p {
    position: relative;
    margin-bottom: 8px;
    padding-left: 26px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  i {
    position: absolute;
    top: 4px;
    left: 0;
    width: 14px;
    height: 14px;
  }
}

.tagsTitle,
.teamTitle {
  margin-bottom: 12px;
  color: @text-color;
  font-weight: 500;
}

.tags {
  :global {
    .ant-tag {
      margin-bottom: 8px;
    }
  }
}

.team {
  :global {
    .ant-avatar {
      margin-right: 12px;
    }
  }

  a {
    display: block;
    margin-bottom: 24px;
    overflow: hidden;
    color: @text-color;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    transition: color 0.3s;

    &:hover {
      color: @primary-color;
    }
  }
}

.tabsCard {
  :global {
    .ant-card-head {
      padding: 0 16px;
    }
  }
}
.subContent {
  overflow: hidden;

  margin: 0 50px 0 50px;
  .text {
    display: flex;
  }
  .percent {
    width: 80%;
  }
}

@media screen and (max-width: @screen-sm) {
  .subCard {
    width: 351px;
  }
  .subContent {
    overflow: hidden;
    margin: 0 -20px 0 -20px;
    .text {
      display: flex;
    }
    .percent {
      width: 80%;
    }
  }
}

@primary-color: #2a5cad;@theme: [object Object];