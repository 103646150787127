@import '~antd/es/style/themes/default.less';

.standardList {
  :global {
    .ant-card-head {
      border-bottom: none;
    }
    .ant-card-head-title {
      padding: 24px 0;
      line-height: 32px;
    }
    .ant-card-extra {
      padding: 24px 0;
    }
    .ant-list-pagination {
      margin-top: 24px;
      text-align: right;
    }
    .ant-avatar-lg {
      width: 48px;
      height: 48px;
      line-height: 48px;
    }
  }
  .headerInfo {
    position: relative;
    text-align: center;
    & > span {
      display: inline-block;
      margin-bottom: 4px;
      color: @text-color-secondary;
      font-size: @font-size-base;
      line-height: 22px;
    }
    & > p {
      margin: 0;
      color: @heading-color;
      font-size: 24px;
      line-height: 32px;
    }
    & > em {
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 56px;
      background-color: @border-color-split;
    }
  }
  .listContent {
    font-size: 0;
    .listContentItem {
      display: inline-block;
      margin-left: 40px;
      color: @text-color-secondary;
      font-size: @font-size-base;
      vertical-align: middle;
      > span {
        line-height: 20px;
      }
      > p {
        margin-top: 4px;
        margin-bottom: 0;
        line-height: 22px;
      }
    }
  }
  .ant-list-item-meta-content {
    margin: auto;
  }
  .extraContentSearch {
    width: 272px;
    margin-left: 16px;
  }
}

@media screen and (max-width: @screen-xs) {
  .standardList {
    :global {
      .ant-list-item-content {
        display: block;
        flex: none;
        width: 100%;
      }
      .ant-list-item-action {
        margin-left: 0;
      }
    }
    .listContent {
      margin-left: 0;
      & > div {
        margin-left: 0;
      }
    }
    .listCard {
      :global {
        .ant-card-head-title {
          overflow: visible;
        }
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .standardList {
    .extraContentSearch {
      width: 100%;
      margin-left: 0;
    }
    .headerInfo {
      margin-bottom: 16px;
      & > em {
        display: none;
      }
    }
    :global {
      .ant-list-item-content {
        display: block;
        flex: none;
        width: 100%;
      }
      .ant-list-item-action {
        margin-left: 0;
      }
    }
    .listContent {
      margin-left: 0;
      & > div {
        margin-left: 0;
      }
    }
    .listCard {
      :global {
        .ant-card-head-title {
          overflow: visible;
        }
      }
    }
  }
}

@media screen and (max-width: @screen-md) {
  .standardList {
    .listContent {
      & > div {
        display: block;
      }
      & > div:last-child {
        top: 0;
        width: 100%;
      }
    }
  }
  .listCard {
    :global {
      .ant-radio-group {
        display: block;
        margin-bottom: 8px;
      }
    }
  }
}

@media screen and (max-width: @screen-lg) and (min-width: @screen-md) {
  .standardList {
    .listContent {
      & > div {
        display: block;
      }
      & > div:last-child {
        top: 0;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: @screen-xl) {
  .standardList {
    .listContent {
      & > div {
        margin-left: 24px;
      }
      & > div:last-child {
        top: 0;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .standardList {
    .listContent {
      text-align: right;
      & > div:last-child {
        top: 0;
      }
    }
  }
}

.standardListForm {
  :global {
    .ant-form-item {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 32px;
        padding-top: 4px;
      }
    }
  }
}

.formResult {
  width: 100%;
  [class^='title'] {
    margin-bottom: 8px;
  }
}

.textOverflow() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.textOverflowMulti(@line: 3, @bg: #fff) {
  position: relative;
  max-height: @line * 1.5em;
  margin-right: -1em;
  padding-right: 1em;
  overflow: hidden;
  line-height: 1.5em;
  text-align: justify;
  &::before {
    position: absolute;
    right: 14px;
    bottom: 0;
    padding: 0 1px;
    background: @bg;
    content: '...';
  }
  &::after {
    position: absolute;
    right: 14px;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
    content: '';
  }
}

// mixins for clearfix
// ------------------------
.clearfix() {
  zoom: 1;
  &::before,
  &::after {
    display: table;
    content: ' ';
  }
  &::after {
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden;
  }
}

@primary-color: #2a5cad;@theme: [object Object];